import React from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import { IField } from "types";
import useAreaColumn from "./Table/useAreaColumn";

export function useTableColumns() {
  const { t } = useTranslation();
  const areaHeader = useAreaColumn();
  const areaSumHeader = useAreaColumn({ aggregate: true });
  const cropHeader = {
    Header: t("common.crop"),
    accessor: "type",
  };
  const aggregateCropHeader = {
    ...cropHeader,
    aggregate: (vals: number[]) => {
      return vals[0];
    },
  };
  const productHeader = { Header: t("common.product"), accessor: "product" };
  const rateHeader = {
    Header: t("common.rate"),
    id: "rate",
    accessor: (row: { rate: number; rateUom: string }) => {
      return `${row.rate} ${row.rateUom}`;
    },
    disableGlobalFilter: true,
  };
  const dateHeader = {
    Header: t("common.date"),
    id: "date",
    accessor: (row: { beginDateUtc: string }) => {
      return new Date(row.beginDateUtc).toLocaleDateString();
    },
    disableGlobalFilter: true,
  };
  const depthHeader = {
    Header: t("common.depth"),
    accessor: (row: { depth: number; depthUom: string }) => {
      return `${row.depth} ${row.depthUom}`;
    },
    disableGlobalFilter: true,
  };
  // const areaHeader = {
  //   Header: t('common.area'),
  //   accessor: (row) => {
  //     return `${row.area} ${row.areaUom}`;
  //   },
  //   disableGlobalFilter: true,
  // };
  // const areaSumHeader = {
  //   Header: t('common.area'),
  //   id: 'area',
  //   aggregate: 'sum',
  //   accessor: 'area',
  //   Cell: ({ value, data }) => {
  //     const areaUom = data[0]?.areaUom;
  //     // return `${row.area} ${row.areaUom}`;
  //     return `${value?.toFixed(2) || ''} ${areaUom || ''}`;
  //   },
  // };

  // NOTE: allow searching by farm, must set in hiddenFields
  // on the table instance props
  const searchOnlyFields = [{ Header: "", accessor: "farm" }];

  const fieldHeader = {
    Header: t("common.field"),
    accessor: "field",
    styles: {
      maxWidth: "175px",
      minWidth: "175px",
    },
    Cell: function FieldCol({
      value,
      row,
    }: {
      value: string;
      row: { original: IField };
    }) {
      return (
        <Box
          sx={{ mr: 1 }}
          style={{ display: "flex", flexDirection: "column", float: "left" }}
        >
          <span>{`${value}`}</span>
          <small>{row.original?.farm}</small>
        </Box>
      );
    },
  };

  const typeHeader = { Header: t("common.type"), accessor: "type" };
  const categoryHeader = {
    Header: t("inventory.products.category"),
    accessor: "category",
  };

  const totalMassHeader = {
    Header: t("common.totalMass"),
    aggregate: "sum",
    accessor: "totalMass",
    Cell: ({
      row,
      value,
      data,
    }: {
      row: { index: number };
      value: number;
      data: { totalMassUom: string }[];
    }) => {
      const uom = data[row?.index]?.totalMassUom;
      // return `${row.area} ${row.areaUom}`;
      return `${value?.toLocaleString() || ""} ${uom || ""}`;
    },
    disableGlobalFilter: true,
  };
  const avgYldHeader = {
    Header: t("common.averageYield"),
    accessor: (row: { averageYieldUom: string; averageYield: number }) => {
      if (row?.averageYieldUom) {
        return `${row.averageYield} ${row.averageYieldUom}`;
      }
      return "";
    },
    disableGlobalFilter: true,
  };
  const avgPercentMoistureHeader = {
    Header: t("inventory.operations.averagePercentMoisture"),
    accessor: "averagePercentMoisture",
    disableGlobalFilter: true,
  };
  const countHeader = {
    Header: t("common.count"),
    accessor: "count",
    disableGlobalFilter: true,
  };

  const operationFieldHeader = {
    Header: t("common.field"),
    accessor: "fieldFarmGroupKey",
    styles: {
      maxWidth: "175px",
      minWidth: "175px",
    },
    Cell: function FieldCol({ value }: { value: string }) {
      const [field, farm] = value.split(":");
      return (
        <Box
          sx={{ mr: 1 }}
          style={{ display: "flex", flexDirection: "column", float: "left" }}
        >
          <span>{`${field}`}</span>
          <small>{farm}</small>
        </Box>
      );
    },
  };
  const operationReportFieldHeader = {
    ...operationFieldHeader,
    accessor: "fieldFarmTypeKey",
  };
  // INVENTORY/OPERATION
  const plantedCols = [
    operationFieldHeader,
    productHeader,
    rateHeader,
    typeHeader,
    areaHeader,
    dateHeader,
    ...searchOnlyFields,
  ];
  const appliedCols = [
    operationFieldHeader,
    typeHeader,
    productHeader,
    rateHeader,
    categoryHeader,
    areaHeader,
    dateHeader,
    ...searchOnlyFields,
  ];
  return {
    operations: {
      planted: plantedCols,
      applied: appliedCols,
      harvested: [
        operationFieldHeader,
        cropHeader,
        totalMassHeader,
        avgYldHeader,
        avgPercentMoistureHeader,
        countHeader,
        areaHeader,
        dateHeader,
        ...searchOnlyFields,
      ],
      tilled: [
        operationFieldHeader,
        { Header: t("common.type"), accessor: "tilledType" },
        depthHeader,
        areaHeader,
        dateHeader,
        ...searchOnlyFields,
      ],
    },

    operationReports: {
      planted: [
        operationReportFieldHeader,
        dateHeader,
        productHeader,
        aggregateCropHeader,
        rateHeader,
        areaSumHeader,
      ],
      applied: [
        operationReportFieldHeader,
        dateHeader,
        productHeader,
        rateHeader,
        areaSumHeader,
      ],
      harvested: [
        operationReportFieldHeader,
        dateHeader,
        aggregateCropHeader,
        totalMassHeader,
        avgYldHeader,
        avgPercentMoistureHeader,
        countHeader,
        areaSumHeader,
      ],
      tilled: [
        operationReportFieldHeader,
        dateHeader,
        depthHeader,
        areaSumHeader,
      ],
    },
    fieldHeader,
  };
}
