import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import NestedLegendItem from "./NestedLegendItem";
import LegendItem from "./LegendItem";
import { ToggleableControlView } from "../ToggleableControl";
import { ThemeProvider } from "lib";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";

function getUniqueFeatures(array, comparatorProperty) {
  const existingFeatureKeys = {};
  // Because features come from tiled vector data, feature geometries may be split
  // or duplicated across tile boundaries and, as a result, features may appear
  // multiple times in query results.
  const uniqueFeatures = array.filter((el) => {
    if (existingFeatureKeys[el.properties[comparatorProperty]]) {
      return false;
    }
    existingFeatureKeys[el.properties[comparatorProperty]] = true;
    return true;
  });

  return uniqueFeatures;
}

function LegendContainer({ data, title, noDataText = "No data", ...rest }) {
  const legendTitle = title || "Legend";
  if (!data) {
    return null;
  }
  // check that there is actually some legend data
  let hasData = false;
  if (data) {
    data.forEach((d, idx) => {
      d.key = `${d.title}-${idx}`;
      if (Array.isArray(d?.data)) {
        if (d?.data?.length) {
          hasData = true;
        }
      } else {
        const keys = d?.data ? Object.keys(d.data) : [];
        if (keys.length) {
          hasData = true;
        }
      }
    });
  }

  return (
    <>
      <ToggleableControlView
        sx={{ maxHeight: "450px", overflow: "auto" }}
        icon={<FormatListBulletedIcon sx={{ fontSize: "1rem" }} />}
        {...rest}
      >
        {legendTitle ? (
          <h3 style={{ textAlign: "center", textTransform: "none" }}>
            {legendTitle}
          </h3>
        ) : null}
        {!hasData ? (
          <p style={{ textAlign: "center", mb: "0.25rem" }}>{noDataText}</p>
        ) : null}
        {data.map((i) => {
          if (!i) {
            return null;
          }
          if (Array.isArray(i.data)) {
            if (i.data.length) {
              return <NestedLegendItem key={i.key} item={i} />;
            }
            return null;
          }
          return <LegendItem key={i.key} data={i.data || {}} />;
        })}
      </ToggleableControlView>
    </>
  );
}

LegendContainer.defaultProps = {
  data: null,
  title: null,
  isToggled: true,
};

LegendContainer.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  title: PropTypes.string,
  isToggled: PropTypes.bool,
  noDataText: PropTypes.string,
};

class LegendControl {
  constructor(opts) {
    this.options = opts || {};
    this.filteredData = [];
    this.getVisibleFeatures = this.getVisibleFeatures.bind(this);
    return this;
  }

  renderReactComponent() {
    ReactDOM.render(
      <ThemeProvider theme={this.options.theme}>
        <LegendContainer
          data={this.filteredData}
          title={this.options.title}
          noDataText={this.options.noDataText}
          isToggled={this.options.isToggled}
          onToggle={(isToggled) => {
            this.options.isToggled = isToggled;
          }}
          location={this.options.location}
        />
      </ThemeProvider>,
      this.container
    );
  }

  getVisibleFeatures() {
    if (!this.map) {
      return;
    }
    const { layers } = this.options;
    if (layers) {
      this.filteredData = [];
      layers.forEach((lyr) => {
        const { isStatic, data, id, ids, dataProp, Symbol } = lyr;

        const lyrIds = [];
        if (!ids && id) {
          lyrIds.push(...[id]);
        } else {
          lyrIds.push(...ids);
        }
        const idsOnMap = lyrIds.filter((i) => this.map.getLayer(i));
        const ftrs = this.map.queryRenderedFeatures(null, {
          layers: idsOnMap,
        });
        if (isStatic && data && idsOnMap?.length) {
          this.filteredData.push({
            title: lyr.title,
            data: data?.map((d) => {
              const ItemSymbol = d.Symbol;
              return {
                legendSymbol: (
                  <ItemSymbol
                    data={d.properties}
                    geometryType={d.geometry?.type}
                  />
                ),
                name: d.name,
              };
            }),
          });
          return;
        }
        const uniqFtrs = getUniqueFeatures(ftrs, dataProp);
        if (!dataProp && uniqFtrs.length) {
          this.filteredData.push({
            title: lyr.title,
            data: { legendSymbol: <Symbol />, name: lyr.title },
          });
          return;
        }

        this.filteredData.push({
          title: lyr.title,
          data: uniqFtrs?.map((d) => {
            const name =
              typeof dataProp === "function"
                ? dataProp(d.properties)
                : d.properties[dataProp];
            return {
              legendSymbol: (
                <Symbol data={d.properties} geometryType={d.geometry?.type} />
              ),
              name,
            };
          }),
        });
      });
      this.renderReactComponent();
    }
  }

  onAdd(map) {
    this.map = map;
    this.container = document.createElement("div");
    this.container.className =
      "mapboxgl-ctrl cai-mapboxgl-ctrl cai-toggleable-ctrl";
    this.renderReactComponent();
    this.map.on("moveend", this.getVisibleFeatures);

    return this.container;
  }

  updateData(data) {
    Object.assign(this.options, data || {});
    if (this.container) {
      this.getVisibleFeatures();
      this.renderReactComponent();
    }
  }

  onRemove() {
    if (this.map) {
      this.map.off("moveend", this.getVisibleFeatures);
    }
    ReactDOM.unmountComponentAtNode(this.container);
    this.container.parentNode.removeChild(this.container);
    this.map = undefined;
  }
}

export default LegendControl;
