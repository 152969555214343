import React from "react";
import PropTypes from "prop-types";
import {
  ResponsiveContainer,
  Bar,
  Cell,
  Line,
  ComposedChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Label,
} from "recharts";
import { CenteredBarLabel } from "components/Charts/utils";
import { useTranslation } from "react-i18next";

// eslint-disable-next-line react/prop-types
const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload) {
    // const { name } = payload[0]?.payload;
    return (
      <div
        className="bg-light text-dark p-2"
        style={{ border: "solid 1px", opacity: 0.9, minWidth: "175px" }}
      >
        <h6
          className="text-center mb-0"
          style={{ fontWeight: "bold" }}
        >{`${label}`}</h6>
        {/* eslint-disable-next-line react/prop-types */}
        {payload.map((p) => (
          <div key={p.name}>
            <span>{p.name}</span>: {p.value?.toLocaleString()}
          </div>
        ))}
      </div>
    );
  }

  return null;
};

function YieldAverage({ data, onMouseEnter, onMouseExit, yAxisUnits }) {
  const { t } = useTranslation();
  return (
    <ResponsiveContainer>
      <ComposedChart
        data={data}
        margin={{ top: 25 }}
        // margin={{ top: 5, right: -30, left: -20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="product"
          stroke="#fff"
          padding={{ left: 100, right: 100 }}
        />
        <YAxis width={80} yAxisId="left" stroke="#fff">
          <Label
            dx={-10}
            value={`${t("common.averageYield")}${
              yAxisUnits ? ` (${yAxisUnits})` : null
            }`}
            angle={-90}
            position="outside"
            fill="#fff"
            fontSize={14}
          />
        </YAxis>
        <YAxis width={120} yAxisId="right" orientation="right" stroke="#fff">
          <Label
            dx={10}
            value={t("insights.yieldCount")}
            angle={-90}
            position="outside"
            fill="#fff"
            fontSize={14}
          />
        </YAxis>
        <Tooltip content={CustomTooltip} labelStyle={{ color: "#333" }} />
        <Bar
          label={CenteredBarLabel}
          yAxisId="left"
          name={t("common.averageYield")}
          dataKey="average"
          barSize={100}
          // fill="#79C80E"
        >
          {data.map((d) => (
            <Cell
              key={JSON.stringify(d)}
              fill={d.color}
              onMouseEnter={() => {
                onMouseEnter(d);
              }}
              onMouseLeave={() => {
                onMouseExit(d);
              }}
            />
          ))}
        </Bar>
        <Line
          name={t("insights.yieldCount")}
          yAxisId="right"
          type="monotone"
          dataKey="count"
          stroke="#D5E100"
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
}

YieldAverage.defaultProps = {
  data: null,
  yAxisUnits: "",
};

YieldAverage.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  onMouseEnter: PropTypes.func.isRequired,
  onMouseExit: PropTypes.func.isRequired,
  yAxisUnits: PropTypes.string,
};

export { YieldAverage };
