import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { GeoJSONSource, Map } from "mapbox-gl";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useOrgState } from "providers/OrgProvider";
import { useFieldsState } from "providers/FieldsProvider";
import InsightsMapView from "../InsightsMapView";
import { YieldAverage } from "./YieldAverageChart";
import { getBufferGeom, getBufferGeomFlattened } from "./utils";
// import { StatsChart } from "./StatsChart";
import { fitBounds } from "lib/MapboxMap";
import { Feature, FeatureCollection } from "geojson";
import { IFetchState } from "types";
import { ChartDataRequestBody, IFieldLookup, YieldType } from "../types";
import { useYieldProducts } from "./useYieldProducts";
import Autocomplete from "lib/Select/Autocomplete";
import {
  useYieldInsightsActions,
  useYieldInsightsState,
} from "./useYieldInsightsStore";
import AverageBy from "./AverageBy";
import {
  useProductAppliedAndPlanted,
  useProductAppliedOrPlanted,
  // useYieldInsightsChartData,
} from "./useYieldInsightsChartData";
import { useOperationAppliedBoundary } from "../useOperationAppliedBoundary";
import { useOperationPlantedBoundaries } from "../useOperationPlantedBoundaries";
import { Grid } from "components/layout/Grid";

const emptyFc: FeatureCollection = {
  type: "FeatureCollection",
  features: [],
};

export default function YieldView() {
  const history = useHistory();
  const { t } = useTranslation();
  const reportTypes = useMemo(
    () => [
      {
        label: t("insights.yield.avgByProductApplied"),
        id: "productapplied",
        value: "productapplied",
      },
      {
        label: t("insights.yield.avgByProductPlanted"),
        id: "productplanted",
        value: "productplanted",
      },
      {
        label: t("insights.yield.avgByProductPlantedApplied"),
        id: "productplantedapplied",
        value: "productplantedapplied",
      },
      // {
      //   label: t("insights.yield.imgResByProductPlantedApplied"),
      //   id: "imagery",
      //   value: "imagery/productplantedapplied",
      // },
    ],
    [t]
  );
  const yieldInsightsState = useYieldInsightsState();
  const { yieldType } = useParams<{ yieldType: YieldType }>();
  const [postData, setPostData] = useState<
    ChartDataRequestBody & { dataProp: string }
  >();
  const { rootUrl, org, season, rasterLegendState } = useOrgState();
  const [unitsOfMeasure] = useState<string>(t("common.meters"));
  const [operationBufferError, setOperationBufferError] = useState(false);
  const mapRef = useRef<Map>();
  // const { getRasterLegend, resetRasterLegend } = useOrgDispatch();
  const { fieldsGeodataState } = useFieldsState() as {
    fieldsGeodataState: IFetchState<FeatureCollection>;
  };
  const [boundaries, setBoundaries] = useState({
    type: "FeatureCollection",
    features: [],
  });

  // const [activeRasterUrl, setActiveRasterUrl] = useState<string>("");
  const yieldProductsQ = useYieldProducts(
    org,
    yieldType,
    yieldInsightsState.field?.fieldId
  );

  const bounds = useRef<Feature>();
  // const yieldInsightsQ = useYieldInsightsChartData(
  //   org,
  //   yieldType,
  //   postData,
  //   yieldProductsQ.data
  // );
  const plantedOrAppliedQ = useProductAppliedOrPlanted({
    org,
    type: yieldType !== "productplantedapplied" ? yieldType : undefined,
    requestBody: postData,
    yieldProducts: yieldProductsQ.data,
  });
  const plantedAndAppliedQ = useProductAppliedAndPlanted(org, postData, {
    enabled: yieldType === "productplantedapplied",
  });
  const yieldInsightsQ =
    yieldType === "productplantedapplied"
      ? plantedAndAppliedQ
      : plantedOrAppliedQ;
  const appliedOpQ = useOperationAppliedBoundary({
    org,
    appliedOperation: yieldInsightsState.appliedOperation,
    enabled: yieldType === "productapplied",
  });
  const plantedOpQ = useOperationPlantedBoundaries({
    org,
    products: yieldInsightsState.products,
    enabled: yieldType === "productplanted",
  });

  const actions = useYieldInsightsActions();
  const addBufferLayer = useCallback((bufferSize, geojson, source) => {
    setOperationBufferError(false);
    const tolerance = 0.00001;
    if ((bufferSize >= 0 || bufferSize <= 0) && geojson && mapRef?.current) {
      const params = {
        size: bufferSize,
        geojson,
        tolerance,
        units: "meters",
      };
      const bufferGeom =
        source === "operation-buff-src"
          ? getBufferGeomFlattened(params)
          : getBufferGeom(params);
      if (!bufferGeom) {
        setOperationBufferError(true);
      }
      const src = mapRef.current.getSource(source) as GeoJSONSource;
      src.setData(bufferGeom || emptyFc);
      return bufferGeom;
    }
    if ((!bufferSize || !geojson) && mapRef?.current) {
      const src = mapRef.current.getSource(source) as GeoJSONSource;
      src.setData(emptyFc);
      return null;
    }
    return null;
  }, []);

  const removeBufferLayer = (src: string) => {
    setOperationBufferError(false);
    if (mapRef.current) {
      const mapSource = mapRef.current.getSource(src) as GeoJSONSource;
      if (mapSource) {
        mapSource.setData(emptyFc);
      }
    }
  };
  const getFormState = useCallback(() => {
    const {
      intersectGeometry,
      appliedOperation,
      collectEvent,
      field,
      products,
      fieldBufferGeometry,
      operationBufferGeometry,
      rasterOptions,
      sensor,
      yieldFilter,
    } = yieldInsightsState;
    return {
      fieldId: field?.fieldId,
      ...(appliedOperation
        ? {
            oaId: appliedOperation?.id,
          }
        : {}),
      ...(yieldFilter
        ? {
            yieldFilter,
          }
        : {}),
      ...(products
        ? {
            [yieldType === "productapplied"
              ? "productIds"
              : "operationIds"]: products?.map((p) => p.id),
          }
        : {}),
      ...(operationBufferGeometry
        ? {
            operationBuffer: JSON.stringify(operationBufferGeometry),
          }
        : {}),
      ...(fieldBufferGeometry
        ? {
            fieldBuffer: JSON.stringify(fieldBufferGeometry),
          }
        : {}),
      ...(collectEvent
        ? {
            collectEventId: collectEvent.id,
          }
        : {}),
      ...(intersectGeometry
        ? {
            geometry: JSON.stringify(intersectGeometry),
          }
        : {}),
      ...(rasterOptions
        ? {
            rasterOptions: rasterOptions.map((r) => r.slug),
          }
        : {}),
      ...(sensor
        ? {
            sensorType: sensor.slug,
          }
        : {}),
    };
  }, [yieldInsightsState, yieldType]);

  function resetOperationsLayer() {
    if (mapRef.current) {
      const src = mapRef.current.getSource("operations") as GeoJSONSource;
      src.setData(emptyFc);
    }
    setBoundaries(emptyFc);
  }

  function setProcessedGeoms(ftrs: FeatureCollection["features"]) {
    if (mapRef.current) {
      const src = mapRef.current.getSource("processed-geoms") as GeoJSONSource;
      src.setData(
        ftrs ? { type: "FeatureCollection", features: ftrs } : emptyFc
      );
    }
  }

  function clearProcessedGeoms() {
    if (mapRef.current) {
      const src = mapRef.current.getSource("processed-geoms") as GeoJSONSource;
      src.setData(emptyFc);
    }
  }

  function handleFieldSelect(field: IFieldLookup) {
    actions.setField(field);
    clearProcessedGeoms();
    resetOperationsLayer();
    setPostData(null);
    const fldGeoJSON = fieldsGeodataState?.data?.features.find(
      (f) => f.id === field?.fieldId
    );
    const fldBuffer = yieldInsightsState.fieldBufferSize
      ? addBufferLayer(
          yieldInsightsState.fieldBufferSize * -1,
          fldGeoJSON,
          "field-buff-src"
        )
      : null;
    if (fldGeoJSON && mapRef.current) {
      bounds.current = fldGeoJSON;
      fitBounds({ geojson: fldGeoJSON, map: mapRef.current });
    }
    actions.setFieldBuffer({
      size: yieldInsightsState.fieldBufferSize,
      geometry: fldBuffer?.geometry,
    });
    return fldBuffer?.geometry;
  }

  function handleFieldBufferChange(bufferSize: number) {
    // setFieldBufferSize(bufferSize);
    if (!bufferSize) {
      actions.setFieldBuffer(null);
      const src = mapRef.current.getSource("field-buff-src") as GeoJSONSource;
      src.setData(emptyFc);
      return;
    }
    const fldGeoJSON = fieldsGeodataState?.data?.features.find(
      (f) => f.id === yieldInsightsState.field?.fieldId
    );
    const ftr = addBufferLayer(bufferSize * -1, fldGeoJSON, "field-buff-src");
    actions.setFieldBuffer({
      size: bufferSize,
      geometry: ftr?.geometry,
    });
    // return ftr?.geometry;
  }

  // const setRasterLayer = useCallback(
  //   (url: string) => {
  //     if (mapRef.current) {
  //       const source = mapRef.current.getSource("raster-lyr-src");
  //       if (source) {
  //         mapRef.current
  //           .removeLayer("raster-lyr")
  //           .removeSource("raster-lyr-src");
  //       }
  //       if (url) {
  //         mapRef.current.addSource("raster-lyr-src", {
  //           type: "raster",
  //           tiles: [url],
  //           maxzoom: 24,
  //           bounds: yieldInsightsState.collectEvent?.extent?.bbox,
  //         });
  //         mapRef.current.addLayer(
  //           {
  //             id: `raster-lyr`,
  //             source: `raster-lyr-src`,
  //             type: "raster",
  //           },
  //           "processed-geoms-layer"
  //         );
  //       }
  //     }
  //   },
  //   [yieldInsightsState.collectEvent?.extent?.bbox]
  // );

  const handleOperationBufferChange = useCallback(
    (n) => {
      let ftr;
      if (n && yieldInsightsState.features) {
        ftr = addBufferLayer(
          n,
          {
            type: "FeatureCollection",
            features: yieldInsightsState.features,
          },
          "operation-buff-src"
        );
      } else {
        removeBufferLayer("operation-buff-src");
      }
      actions.setOperationBuffer({
        size: n,
        geometry: ftr?.geometry,
      });
      return ftr;
    },
    [actions, addBufferLayer, yieldInsightsState.features]
  );

  async function onSubmit(prop: string) {
    resetOperationsLayer();
    // clearProcessedGeoms();
    removeBufferLayer("field-buff-src");
    removeBufferLayer("operation-buff-src");
    // setActiveRasterUrl(null);
    // setRasterLayer(null);
    const body = { ...getFormState(), dataProp: prop };
    setPostData(body);
  }

  const handleReset = useCallback(() => {
    actions.reset();
    resetOperationsLayer();
    setPostData(null);
    actions.setFieldBuffer(null);
    // setFieldBufferSize(null);
    // setActiveRasterUrl(null);
    // setRasterLayer(null);
    removeBufferLayer("field-buff-src");
    removeBufferLayer("operation-buff-src");
    // resetRasterLegend();
    clearProcessedGeoms();
  }, [actions]);

  // set boundary features when product planted/applied data changes
  useEffect(() => {
    const d = appliedOpQ.data ? [appliedOpQ.data] : plantedOpQ.data;
    if (d) {
      actions.setFeatures(d);
      setBoundaries({
        type: "FeatureCollection",
        features: d,
      });
    }
  }, [actions, appliedOpQ.data, plantedOpQ.data]);

  // set boundary features when state features change (product planted AND applied)
  useEffect(() => {
    clearProcessedGeoms();
    setPostData(null);
    // setRasterLayer(null);
    // setActiveRasterUrl(null);
    if (yieldInsightsState.features) {
      setBoundaries({
        type: "FeatureCollection",
        features: yieldInsightsState.features,
      });
    }
  }, [
    yieldInsightsState.field,
    yieldInsightsState.features,
    yieldType,
    // setRasterLayer,
  ]);

  // update processed features when query data changes
  useEffect(() => {
    if (yieldInsightsQ.data?.processedFtrs) {
      setProcessedGeoms(yieldInsightsQ.data.processedFtrs);
    } else {
      clearProcessedGeoms();
    }
    mapRef.current?.resize();
    // update bounds after chart resizes map
    if (bounds.current) {
      fitBounds({ geojson: bounds.current, map: mapRef.current });
    }
  }, [yieldInsightsQ.data]);

  // reset on season change
  useEffect(() => {
    handleReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [season]);

  return (
    <>
      <h2>
        {t("insights.yield.title")} {t("insights.title")}
      </h2>
      <Grid height="100%" container>
        {/* LEFT SIDE */}
        <Grid height="100%" md={4}>
          <Paper sx={{ height: "100%", p: 3, overflow: "auto" }}>
            <Autocomplete
              label={t("insights.selectReportType")}
              id="report-type-select"
              value={reportTypes.find((r) => r.id === yieldType) ?? null}
              options={reportTypes}
              onChange={(_e, item) => {
                handleReset();
                if (!Array.isArray(item) && typeof item !== "string") {
                  history.push(`${rootUrl}/insights/yield/${item.value}`);
                }
              }}
            />
            <Divider sx={{ my: 2 }} />
            <AverageBy
              operationBufferError={operationBufferError}
              isLoading={yieldInsightsQ.isFetching}
              units={unitsOfMeasure}
              onFieldSelect={handleFieldSelect}
              onFieldBufferChange={handleFieldBufferChange}
              onOperationBufferChange={handleOperationBufferChange}
              onAppOrSeedChange={(d) => {
                removeBufferLayer("operation-buff-src");
                if (!d.application && !d.seed) {
                  resetOperationsLayer();
                }
              }}
              onSubmit={() => {
                onSubmit(yieldType);
              }}
            />
            {yieldInsightsQ?.isError ? (
              <Alert severity="error">
                {yieldInsightsQ.error.message || "Unknown error occurred"}
              </Alert>
            ) : null}
            {/* {error ? <Alert severity="error">{error}</Alert> : null} */}
          </Paper>
        </Grid>
        {/* RIGHT SIDE */}
        <Grid display={"flex"} flexDirection={"column"} md={8} height="100%">
          <Box style={{ height: "100%", minHeight: 500 }}>
            <InsightsMapView
              rasterLegendState={rasterLegendState}
              fieldsGeodataState={fieldsGeodataState}
              operationsGeodata={boundaries}
              onMapLoad={(map) => {
                mapRef.current = map;
              }}
            />
          </Box>
          {yieldInsightsQ.data?.chartData ? (
            <Paper
              style={{
                display: "flex",
                flexDirection: "column",
                height: "300px",
              }}
            >
              <YieldAverage
                yAxisUnits={yieldInsightsState.field?.yieldUom}
                data={yieldInsightsQ.data?.chartData}
                onMouseExit={() => {
                  const layer = mapRef.current.getLayer(
                    "operations-layer-active"
                  );
                  if (layer) {
                    mapRef.current.removeLayer("operations-layer-active");
                  }
                }}
                onMouseEnter={(data) => {
                  const { operationId } = data;
                  if (!operationId) {
                    return;
                  }
                  const layer = mapRef.current.getLayer(
                    "operations-layer-active"
                  );
                  if (!layer) {
                    mapRef.current.addLayer({
                      id: "operations-layer-active",
                      type: "fill",
                      source: "processed-geoms",
                      filter: ["==", "operationId", operationId],
                      paint: {
                        "fill-color": "cyan",
                      },
                    });
                  } else {
                    mapRef.current.setFilter("operations-layer-active", [
                      "==",
                      "operationId",
                      operationId,
                    ]);
                  }
                }}
              />
            </Paper>
          ) : null}
        </Grid>
      </Grid>
    </>
  );
}
