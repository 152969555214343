import React from "react";
import PropTypes from "prop-types";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  Tooltip,
  XAxis,
  YAxis,
  Cell,
} from "recharts";
import { TotalMassTooltip } from "./TotalMassTooltip";

export default function TotalMassBarChart({
  data,
  isActive,
  // onClick,
  // id,
  title,
  showTooltip,
  showActiveBorder,
}) {
  let borderBottom = "solid 4px transparent";
  if (!showActiveBorder) {
    borderBottom = "none";
  } else if (isActive) {
    borderBottom = "solid 4px";
  }

  // NOTE: manually setting domain to largest value of totalMass
  // due to issue with recharts
  const totalMassVals = data?.map((entry) => Number(entry.totalMass));
  const maxVal = Math.max(...totalMassVals);

  return (
    <>
      <h6 style={{ textAlign: "center", margin: 0 }} title={title}>
        {title}
      </h6>
      <div
        style={{
          height: "150px",
          width: "100%",
          borderBottom,
        }}
      >
        <ResponsiveContainer>
          <BarChart
            layout="vertical"
            isAnimationActive={false}
            data={data}
            // style={{ cursor: 'pointer' }}
          >
            <XAxis
              stroke="#fff"
              tickLine={{ stroke: "#fff" }}
              tick={{ fill: "#fff" }}
              tickFormatter={(val) => {
                // eslint-disable-next-line react/prop-types
                const totalMassUom = data?.length ? data[0].totalMassUom : "";
                return `${val.toLocaleString()} ${totalMassUom}`;
              }}
              type="number"
              domain={[0, maxVal]}
            />
            <YAxis
              stroke="#fff"
              tick={{ fill: "#fff" }}
              type="category"
              dataKey="name"
            />
            <Bar data={data} dataKey="totalMass" fill="#000">
              {data?.map((entry) => (
                <Cell key={entry.name} fill={entry.color} />
              ))}
            </Bar>
            {showTooltip ? (
              <Tooltip
                cursor={{ fill: "transparent" }}
                content={<TotalMassTooltip />}
                allowEscapeViewBox={{ x: true, y: true }}
                wrapperStyle={{ zIndex: 3 }}
              />
            ) : null}
          </BarChart>
        </ResponsiveContainer>
      </div>
      {/* </Button> */}
    </>
  );
}
TotalMassBarChart.defaultProps = {
  data: null,
  isActive: false,
  // onClick: () => {},
  showTooltip: true,
  showActiveBorder: true,
};
TotalMassBarChart.propTypes = {
  // id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})),
  isActive: PropTypes.bool,
  // onClick: PropTypes.func,
  showTooltip: PropTypes.bool,
  showActiveBorder: PropTypes.bool,
};
