import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";

export const OperationTooltip = ({
  unit,
  dataIdx,
  dataKey,
  active,
  payload,
}) => {
  const { t } = useTranslation();
  if (active && payload) {
    const item = payload.find((p) => p.dataKey === dataKey) || payload[0];
    const barValue = dataKey && item?.payload ? item.payload[dataKey] : null;
    const childItem =
      dataKey && (dataIdx || dataIdx === 0)
        ? item?.payload?.children[dataIdx]
        : null;
    const { product, beginDateUtc, field, rate, rateUom, type } =
      childItem || item?.payload || {};
    return (
      <Box
        sx={{ p: 1, bgcolor: "Background", color: "common.black" }}
        style={{ border: "solid 1px", opacity: 0.9, minWidth: "175px" }}
      >
        <h6 style={{ textAlign: "center", fontWeight: "bold" }}>{`${t(
          "common.field"
        )}: ${field}`}</h6>
        <div>{`${product ? `${product} - ` : ""}${type ? `${type}` : ""}`}</div>
        {barValue ? (
          <>
            <div>{`${barValue} ${unit}`}</div>
            {rate ? <div>{`${rate} ${rateUom}`}</div> : null}
            <div>{new Date(beginDateUtc).toLocaleDateString()}</div>
          </>
        ) : null}
      </Box>
    );
  }

  return null;
};
export default OperationTooltip;

OperationTooltip.defaultProps = {
  active: false,
  payload: null,
  dataKey: null,
  dataIdx: null,
  unit: null,
};

OperationTooltip.propTypes = {
  dataKey: PropTypes.string,
  dataIdx: PropTypes.number,
  unit: PropTypes.string,
  active: PropTypes.bool,
  payload: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
    })
  ),
};
